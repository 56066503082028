function slugify(str) {
  if (str[0].length > 1) {
    return str[0]
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "")
  }
}

export default slugify
