import React from "react"
import { graphql } from "gatsby"
import "../styles/components/blogpost.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Head from "../components/head"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types"
import SyntaxHighlighter from "react-syntax-highlighter"
import { atelierForestDark } from "react-syntax-highlighter/dist/esm/styles/hljs"
import slugify from "../utils/slugify"

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      excerpt
      publishedDate(formatString: "MMMM Do, YYYY")
      publishedAtDate: publishedDate
      updatedAt(formatString: "MMMM Do, YYYY")
      updatedAtDate: updatedAt
      author {
        fullName
        profileImage {
          gatsbyImageData
        }
      }
      slug
      postIcon {
        url
        title
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(placeholder: BLURRED)
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Blog = props => {
  const body = JSON.parse(props.data.contentfulBlogPost.body.raw)
  const empIds = ["heading-2"]
  const headings = body.content.filter(i => empIds.includes(i.nodeType))

  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${props.data.site.siteMetadata.siteUrl}${props.data.contentfulBlogPost.slug}`,
    },
    headline: `${props.data.contentfulBlogPost.title}`,
    description: `${props.data.contentfulBlogPost.excerpt}`,
    image: `${props.data.contentfulBlogPost.postIcon.url}`,
    author: {
      "@type": "Person",
      name: `${props.data.contentfulBlogPost.author.fullName}`,
      url: `${props.data.site.siteMetadata.siteUrl}/about/`,
    },
    publisher: {
      "@type": "Person",
      name: `${props.data.contentfulBlogPost.author.fullName}`,
    },
    datePublished: `${props.data.contentfulBlogPost.publishedAtDate}`,
    dateModified: `${props.data.contentfulBlogPost.updatedAtDate}`,
  }

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        return (
          <>
            <GatsbyImage
              className="max-w-full mb-8"
              image={node.data.target.gatsbyImageData}
              alt={node.data.target.title}
            />
          </>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 id={`${slugify(children)}`}>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 id={`${slugify(children)}`}>{children}</h3>
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return (
          <blockquote className="prose-p:p-4 prose-p:text-white prose-a:text-white border-l-8 border-indigo-900 bg-indigo-700">
            {children}
          </blockquote>
        )
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className="ml-4 prose-p:mb-4">{children}</li>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (
          node.content.length === 1 &&
          node.content[0].marks.find(x => x.type === "code")
        ) {
          return <div>{children}</div>
        }
        return <p>{children}</p>
      },
      [INLINES.HYPERLINK]: node => {
        return (
          <a
            className="underline text-indigo-400 hover:text-indigo-300 focus:text-indigo-500"
            href={node.data.uri}
            target={`${
              node.data.uri.startsWith(props.data.site.siteMetadata.siteUrl)
                ? "_self"
                : "_self"
            }`}
            rel={`${
              node.data.uri.startsWith(props.data.site.siteMetadata.siteUrl)
                ? ""
                : "noopener noreferrer"
            }`}
          >
            {node.content[0].value}
          </a>
        )
      },
    },
    renderMark: {
      [MARKS.CODE]: text => {
        return (
          <SyntaxHighlighter
            className="w-full max-w-full mb-8"
            style={atelierForestDark}
            wrapLines
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
            }}
          >
            {text}
          </SyntaxHighlighter>
        )
      },
    },
  }

  return (
    <Layout>
      <Head
        title={`${props.data.contentfulBlogPost.title} | ${props.data.contentfulBlogPost.author.fullName}`}
        description={`${props.data.contentfulBlogPost.excerpt}`}
        image={`${props.data.contentfulBlogPost.postIcon.url}`}
        url={`${props.data.site.siteMetadata.siteUrl}/${props.data.contentfulBlogPost.slug}`}
        schemaMarkup={schema}
      />

      <div className="px-4 mt-8 w-full overflow-x-scroll">
        <header className="border-b-2 border-zinc-500/30 pb-6">
          <div className="flex flex-col">
            <div className="flex flex-col mt-4 mr-auto">
              <h1 className="text-zinc-200 sm:text-5xl text-4xl font-bold">
                {props.data.contentfulBlogPost.title}
              </h1>
              <div className="flex flex-row align-middle mt-6 mr-auto">
                <GatsbyImage
                  className="w-12 rounded-full"
                  image={
                    props.data.contentfulBlogPost.author.profileImage
                      .gatsbyImageData
                  }
                  alt={props.data.contentfulBlogPost.author.fullName}
                />
                <div className="ml-3">
                  <p className="text-zinc-300 leading-5 text-lg font-semibold">
                    {props.data.contentfulBlogPost.author.fullName}
                  </p>
                  <p className="text-zinc-300">
                    Updated {props.data.contentfulBlogPost.updatedAt} (Published{" "}
                    {props.data.contentfulBlogPost.publishedDate})
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-zinc-300 text-lg font-normal mt-6">
            {props.data.contentfulBlogPost.excerpt}
          </p>
        </header>
        <div
          className="mt-8 
        prose-ol:list-decimal prose-ol:mb-8 
        prose-li:ml-4 prose-li:mt-1.5 prose-li:text-thin prose-li:text-zinc-300"
        >
          <h2 className="mt-8 mb-3 text-3xl font-bold text-zinc-200">
            Table of contents:{" "}
          </h2>
          <ol>
            {headings.map(edge => {
              return (
                <li key={`${edge.content[0].value}`}>
                  <a
                    href={`#${slugify([edge.content[0].value])}`}
                    className="leading-7 mb-4 font-normal underline text-indigo-400 hover:text-indigo-300 focus:text-indigo-500 text-lg"
                  >
                    {edge.content[0].value}
                  </a>
                </li>
              )
            })}
          </ol>
        </div>
        <article
          className="mt-8 prose:break-words 
        prose-li:ml-4 prose-li:mt-1.5 prose-li:text-thin prose-li:text-zinc-300
        prose-h2:mt-8 prose-h2:mb-3 prose-h2:text-3xl prose-h2:font-bold prose-h2:text-zinc-200
        prose-h3:mt-6 prose-h3:mb-2 prose-h3:text-2xl prose-h3:font-bold prose-h3:text-zinc-200
        prose-h4:mt-4 prose-h4:mb-2 prose-h4:text-xl prose-h4:font-bold prose-h4:text-zinc-200
        prose-ol:list-decimal prose-ol:mb-8
        prose-ul:list-disc prose-ul:mb-8
        prose-p:text-zinc-300 prose-p:text-lg prose-p:leading-7 prose-p:mb-8 prose-p:font-normal"
        >
          {renderRichText(props.data.contentfulBlogPost.body, options)}
        </article>
      </div>
    </Layout>
  )
}

export default Blog
